var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":""}},[_c('v-card',[_c('hpo-filter',{attrs:{"headers":_vm.headers,"viewName":_vm.viewName},on:{"applyFilter":_vm.applyFilter,"quickFilter":_vm.quickFilter}}),_c('v-data-table',{attrs:{"fixed-header":"","height":"750px","headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.total_rows,"footer-props":_vm.items_per_page,"must-sort":true,"loading":_vm.loading,"mobile-breakpoint":100},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('table-header-filter',{attrs:{"headers":props.headers,"viewName":_vm.viewName},on:{"applyFilter":_vm.applyFilter}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{attrs:{"id":'r-' + item.ID}},[_c('td',[_vm._v(_vm._s(item.TransactionCode))]),_c('td',[_c('a',{attrs:{"target":"blank","href":(item.OrderType == 1 ? '/don-hang/' : '/ky-gui/') + item.OrderID}},[_vm._v(_vm._s((item.OrderType == 1 ? 'MD' : 'KG') +item.OrderID))])]),_c('td',[_vm._v(_vm._s(item.Username))]),_c('td',{style:({ 'color': _vm.get_status_color(item.PaymentStatus, _vm.payment_status)})},[_vm._v(_vm._s(_vm._f("display_value")(item.PaymentStatus,_vm.payment_status)))]),_c('td',{style:({ 'color': _vm.get_status_color(item.Status, _vm.package_status)})},[_vm._v(_vm._s(_vm._f("display_value")(item.Status,_vm.package_status)))]),_c('td',{staticClass:"truncate"},[_c('v-edit-dialog',{attrs:{"return-value":item.StoreVNNote,"large":"","lazy":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "StoreVNNote", $event)},"update:return-value":function($event){return _vm.$set(item, "StoreVNNote", $event)},"save":function($event){return _vm.saveItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-textarea',{attrs:{"label":"Ghi chú","autofocus":""},model:{value:(item.StoreVNNote),callback:function ($$v) {_vm.$set(item, "StoreVNNote", $$v)},expression:"item.StoreVNNote"}})]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"top":"","absolute":"","right":"","offset-overflow":"","max-width":"250","color":"light-green lighten-5 black--text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.StoreVNNote))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.StoreVNNote))])])],1)],1),_c('td',{staticClass:"truncate"},[_c('v-edit-dialog',{attrs:{"return-value":item.ShippingInfo,"large":"","lazy":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "ShippingInfo", $event)},"update:return-value":function($event){return _vm.$set(item, "ShippingInfo", $event)},"save":function($event){return _vm.saveItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-textarea',{attrs:{"label":"Thông tin nhận hàng","autofocus":""},model:{value:(item.ShippingInfo),callback:function ($$v) {_vm.$set(item, "ShippingInfo", $$v)},expression:"item.ShippingInfo"}})]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"top":"","absolute":"","right":"","offset-overflow":"","max-width":"250","color":"light-green lighten-5 black--text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.ShippingInfo))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.ShippingInfo))])])],1)],1),_c('td',{staticClass:"truncate"},[_c('v-edit-dialog',{attrs:{"return-value":item.ShippingMethod,"large":"","lazy":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "ShippingMethod", $event)},"update:return-value":function($event){return _vm.$set(item, "ShippingMethod", $event)},"save":function($event){return _vm.saveItem(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-textarea',{attrs:{"label":"Hình thức gửi","autofocus":""},model:{value:(item.ShippingMethod),callback:function ($$v) {_vm.$set(item, "ShippingMethod", $$v)},expression:"item.ShippingMethod"}})]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"top":"","absolute":"","right":"","offset-overflow":"","max-width":"250","color":"light-green lighten-5 black--text"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.ShippingMethod))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.ShippingMethod))])])],1)],1),_c('td',{staticClass:"text-center"},[(item.Status == 7)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.deliverPackage(item.ID, 6)}}},on),[_vm._v("fa-undo")])]}}],null,true)},[_c('span',[_vm._v("Huỷ xuất kho")])]):_vm._e(),(item.Status == 6)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.deliverPackage(item.ID, 7)}}},on),[_vm._v("fa-dolly-flatbed")])]}}],null,true)},[_c('span',[_vm._v("Xuất kho")])]):_vm._e()],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }